import firebase from 'firebase/app';
import 'firebase/analytics';
import { isExternalUser } from 'tds-common-fe';
import config from '../config';
import {
    EventName,
    EventParameter,
    MeasurementPage,
    ProbeDetailsPage,
    SnapshotAction,
    DiscoveryCategory,
    PersonalProfileAction,
    MeasurementMenuCategory,
    PersonalProfileMenu,
    ShareLinkAction,
    ProductName,
    FolderManagementCategory,
    FileManagementCategory,
    ExportAction,
    SubscriptionAction,
    InsightsAction,
    SecurityAction,
    UploadCustomCurveAction,
    SnapshotModalAction,
    ImageSliderAction,
    ImageSliderCategory,
    UploadCadgisFileAction,
} from './analyticsConstants';

const standardiseProductName = (product: string) => {
    return ProductName[product] || product.toLowerCase();
};

enum FirebaseInstanceName {
    insights = 'insights',
    workspace = 'workspace',
}

const FIREBASE_CONFIGS: Record<FirebaseInstanceName, any> = {
    [FirebaseInstanceName.insights]: {
        apiKey: config.INSIGHTS_FIREBASE_API_KEY,
        authDomain: `${config.INSIGHTS_FIREBASE_PROJECT_ID}.firebaseapp.com`,
        databaseURL: `https://${config.INSIGHTS_FIREBASE_PROJECT_ID}.firebaseio.com`,
        projectId: config.INSIGHTS_FIREBASE_PROJECT_ID,
        storageBucket: `${config.INSIGHTS_FIREBASE_PROJECT_ID}.appspot.com`,
        messagingSenderId: config.INSIGHTS_FIREBASE_SENDER_ID,
        appId: config.INSIGHTS_FIREBASE_APP_ID,
        measurementId: `G-${config.INSIGHTS_FIREBASE_MEASUREMENT_ID}`,
    },
    [FirebaseInstanceName.workspace]: {
        apiKey: config.WORKSPACE_FIREBASE_API_KEY,
        authDomain: `${config.WORKSPACE_FIREBASE_PROJECT_ID}.firebaseapp.com`,
        databaseURL: `https://${config.WORKSPACE_FIREBASE_PROJECT_ID}.firebaseio.com`,
        projectId: config.WORKSPACE_FIREBASE_PROJECT_ID,
        storageBucket: `${config.WORKSPACE_FIREBASE_PROJECT_ID}.appspot.com`,
        messagingSenderId: config.WORKSPACE_FIREBASE_SENDER_ID,
        appId: config.WORKSPACE_FIREBASE_APP_ID,
        measurementId: `G-${config.WORKSPACE_FIREBASE_MEASUREMENT_ID}`,
    },
};

class FirebaseAnalytics {
    private instance?: firebase.analytics.Analytics;
    private insightsInstance?: firebase.analytics.Analytics;
    private setInsightsUserProperties: boolean = true;

    initializeInstance = async (instanceName: FirebaseInstanceName) => {
        const isSupported = await firebase.analytics.isSupported();
        if (!isSupported) {
            return;
        }
        if (instanceName === FirebaseInstanceName.workspace && this.instance === undefined) {
            this.instance = firebase.initializeApp(FIREBASE_CONFIGS[instanceName]).analytics();
        }
        if (instanceName === FirebaseInstanceName.insights && this.insightsInstance === undefined) {
            this.insightsInstance = firebase.initializeApp(FIREBASE_CONFIGS[instanceName], instanceName).analytics();
        }
    };

    // this is to initialize the default workspace instance
    initialize = async () => {
        await this.initializeInstance(FirebaseInstanceName.workspace);
        this.instance?.setUserProperties({
            app_version: config.APP_VERSION,
        });
    };

    initializeInsightsInstance = async () => {
        await this.initializeInstance(FirebaseInstanceName.insights);
    };

    setUserProperty = (email: string) => {
        this.instance?.setUserProperties({
            is_external_user: isExternalUser(email),
        });
    };

    setCurrentScreen = (name: string) => {
        this.instance?.setCurrentScreen(name);
    };

    logClickHeaderLogo = () => {
        this.instance?.logEvent(EventName.ClickHeaderLogo);
    };

    logButtonClick = (buttonName: string) => {
        this.instance?.logEvent(EventName.ButtonClick, {
            button_name: buttonName,
        });
    };

    logSwitchProfileMenu = (category?: PersonalProfileMenu) => {
        this.instance?.logEvent(EventName.SwitchProfileMenu, {
            [EventParameter.category]: category,
        });
    };

    logViewProfileMenu = (menuItem: string) => {
        this.instance?.logEvent(EventName.ViewProfileMenu, {
            menu_item: menuItem,
        });
    };

    logSidebarSwitchProduct = (product: string) => {
        this.instance?.logEvent(EventName.SidebarSwitchProduct, {
            [EventParameter.product]: standardiseProductName(product),
        });
    };

    logMeasurementView = (page: MeasurementPage, product?: string) => {
        this.instance?.logEvent(EventName.ViewMeasurement, {
            [EventParameter.page]: page,
            [EventParameter.product]: product ? standardiseProductName(product) : undefined,
        });
    };

    logViewProbe = (page: ProbeDetailsPage) => {
        this.instance?.logEvent(EventName.ViewProbeDetails, {
            [EventParameter.page]: page,
        });
    };

    logViewAllProbes = () => {
        this.instance?.logEvent(EventName.ViewAllProbes);
    };

    logViewDiscovery = (category: DiscoveryCategory, contentURL: string) => {
        this.instance?.logEvent(EventName.ViewDiscovery, {
            [EventParameter.action]: category,
            content_url: contentURL,
        });
    };

    logViewExportOptions = (page: MeasurementPage, product: string) => {
        this.instance?.logEvent(EventName.ViewExportOptions, {
            [EventParameter.page]: page,
            [EventParameter.product]: standardiseProductName(product),
        });
    };

    logExportMeasurement = (
        page: MeasurementPage,
        product: string,
        format: string,
        fileCount: number,
        action: ExportAction,
        language?: string
    ) => {
        this.instance?.logEvent(EventName.ExportMeasurement, {
            [EventParameter.page]: page,
            [EventParameter.product]: standardiseProductName(product),
            format,
            file_count: fileCount,
            file_language: language,
            [EventParameter.action]: action,
        });
    };

    logViewLogbook = (product?: string) => {
        this.instance?.logEvent(EventName.ViewLogBook, {
            [EventParameter.product]: product ? standardiseProductName(product) : undefined,
        });
    };

    logDownloadSnapshot = (action: SnapshotAction, product?: string, type?: string) => {
        this.instance?.logEvent(EventName.DownloadSnapshot, {
            [EventParameter.action]: action,
            [EventParameter.product]: product ? standardiseProductName(product) : undefined,
            type,
        });
    };

    logDownloadAttachment = (product?: string) => {
        this.instance?.logEvent(EventName.DownloadAttachment, {
            [EventParameter.product]: product ? standardiseProductName(product) : undefined,
        });
    };

    logSortTable = (column: string) => {
        this.instance?.logEvent(EventName.SortTable, {
            column,
        });
    };

    logMeasurementMenu = (category: MeasurementMenuCategory) => {
        this.instance?.logEvent(EventName.ClickMeasurementMenu, {
            [EventParameter.category]: category,
        });
    };

    logNonEditableMeasurement = (category: MeasurementMenuCategory | FileManagementCategory, product: string) => {
        this.instance?.logEvent(EventName.NonEditableMeasurement, {
            [EventParameter.category]: category,
            [EventParameter.product]: standardiseProductName(product),
        });
    };

    logInsightsAction = (action: InsightsAction) => {
        this.instance?.logEvent(EventName.InsightsActions, {
            [EventParameter.action]: action,
        });
    };

    logFileManagement = (category: FileManagementCategory, product: string) => {
        this.instance?.logEvent(EventName.FileManagement, {
            [EventParameter.category]: category,
            [EventParameter.product]: standardiseProductName(product),
        });
    };

    logFolderManagement = (category: FolderManagementCategory, product: string) => {
        this.instance?.logEvent(EventName.FolderManagement, {
            [EventParameter.category]: category,
            [EventParameter.product]: standardiseProductName(product),
        });
    };

    logRefreshMeasurements = (product: string) => {
        this.instance?.logEvent(EventName.RefreshMeasurements, {
            [EventParameter.product]: standardiseProductName(product),
        });
    };

    logShareLinkEvent = (action: ShareLinkAction, product: string) => {
        this.instance?.logEvent(EventName.ShareLink, {
            [EventParameter.action]: action,
            [EventParameter.product]: standardiseProductName(product),
        });
    };

    logPersonalProfileEvent = (action: PersonalProfileAction) => {
        this.instance?.logEvent(EventName.EditPersonalProfile, {
            [EventParameter.action]: action,
        });
    };

    logSubscriptionEvent = (action: SubscriptionAction, product: string) => {
        this.instance?.logEvent(EventName.ViewSubscriptions, {
            [EventParameter.action]: action,
            [EventParameter.product]: standardiseProductName(product),
        });
    };

    logDeleteAccount = (product: string) => {
        this.instance?.logEvent(EventName.DeleteAccount, {
            [EventParameter.product]: standardiseProductName(product),
        });
    };

    // 2FA
    logProfileSecurity = (action: SecurityAction) => {
        this.instance?.logEvent(EventName.EditSecurity, {
            [EventParameter.action]: action,
        });
    };

    // insights logging
    logInsightsLicenseTier = (license: string) => {
        this.insightsInstance?.setUserProperties({
            license_tier: license,
        });
    };

    logInsightsEvent = (
        event: string,
        email: string,
        appVersion: string,
        params?: {
            [key: string]: any;
        }
    ) => {
        if (this.setInsightsUserProperties) {
            this.insightsInstance?.setUserProperties({
                is_external_user: isExternalUser(email),
                app_version: appVersion,
            });
            this.setInsightsUserProperties = false;
        }
        this.insightsInstance?.logEvent(event, params);
    };

    // UT curve upload
    logUploadCustomCurve = (action: UploadCustomCurveAction, product: string) => {
        this.instance?.logEvent(EventName.UploadCustomCurve, {
            [EventParameter.action]: action,
            [EventParameter.product]: standardiseProductName(product),
        });
    };

    // spatial file upload
    logUploadCadgisFile = (action: UploadCadgisFileAction) => {
        this.instance?.logEvent(EventName.uploadCadgisFile, {
            [EventParameter.action]: action,
        });
    };

    // GM sync data
    logSyncMeasurement = (product: string) => {
        this.instance?.logEvent(EventName.syncMeasurement, {
            [EventParameter.product]: standardiseProductName(product),
        });
    };

    logLaunchApp = (product: string, action: string) => {
        this.instance?.logEvent(EventName.launchApp, {
            [EventParameter.action]: action,
            [EventParameter.product]: standardiseProductName(product),
        });
    };

    logSnapshotModal = (action: SnapshotModalAction, product?: string) => {
        this.instance?.logEvent(EventName.SnapshotModal, {
            [EventParameter.action]: action,
            ...(product ? { [EventParameter.product]: standardiseProductName(product) } : {}),
        });
    };

    logImageSliderAction = (params: { action: ImageSliderAction; product?: string; category: ImageSliderCategory }) => {
        const { action, product, category } = params;
        this.instance?.logEvent(EventName.ImageSlider, {
            [EventParameter.action]: action,
            [EventParameter.category]: category,
            ...(product ? { [EventParameter.product]: standardiseProductName(product) } : {}),
        });
    };
}

const analytics = new FirebaseAnalytics();

export default analytics;
